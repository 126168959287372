<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('transfer.title') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('refresh') }}</el-button>
        </div>
        <div v-loading="loading" class="p-20 border-bottom">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :show-message="false"
                :status-icon="true"
                :rules="rules"
            >
                <el-form-item>
                    <div class="d-flex-center font-16">
                        <span class="mr-auto">{{ $t('available') }}</span>
                        <span class="ml-5">{{ walletList[fi].available }}</span>
                        <span class="ml-5">{{ coinList[ci] }}</span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="d-flex-center">
                        <el-select v-model="fi" prefix-icon="el-icon-money" placeholder="Select Address" class="w-100" @change="bindFromTabChange()">
                            <el-option v-for="(item, index) in walletList" :key="index" :label="walletLabal[item.name]" :value="index" />
                        </el-select>
                        <i class="el-icon-d-arrow-right" style="margin-left: 20px; margin-right: 20px;" @click="switchType()" />
                        <el-select v-model="ti" prefix-icon="el-icon-money" placeholder="Select Address" class="w-100" @change="bindToTabChange()">
                            <el-option v-for="(item, index) in walletList" :key="index" :label="walletLabal[item.name]" :value="index" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item prop="amount">
                    <div class="d-flex-center">
                        <el-input v-model="formData.amount" prefix-icon="el-icon-money" :placeholder="$t('quantity')">
                            <template slot="suffix">
                                <span class="mx-5">{{ formData.coin }}</span>
                            </template>
                        </el-input>
                        <el-button type="primary" plain @click="setMax()">{{ $t('MAX') }}</el-button>
                    </div>
                </el-form-item>
                <el-form-item class="mt-30">
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm()">{{ $t('submit') }}</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="font-12 color-gray mx-20 px-20 border-top text-center">
                <p>
					The swap will be completed immediately after the order is successfully submitted;
				</p>
				<p>
					The swap price is based on the latest price on the server, the current price on the client may be updated with a delay !
				</p>	
            </div> -->
        </div>
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('transfer.history.title') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-14">
            <el-col :span='6'>{{ $t('order_no') }}</el-col>
            <el-col :span='6'>{{ $t('wallet.title') }}</el-col>
            <el-col :span='7'>{{ $t('quantity') }}</el-col>
            <el-col :span='5' class="text-right">{{ $t('date_time') }}</el-col>
        </el-row>
        <div v-if="list.data.length" v-loading="loadingRecord">
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                <el-col :span='6'>{{ item.order_id }}</el-col>
                <el-col :span='6'>{{ walletLabal[item.from_wallet] }} <i class="el-icon-right"></i> {{ walletLabal[item.to_wallet] }}</el-col>
                <el-col :span='7'>{{ item.amount }} {{ item.coin }}</el-col>
                <el-col :span='5' class="text-right">{{ item.create_time }}</el-col>
            </el-row>
            <div class="d-flex-center pt-20">
                <el-pagination
                    :hide-on-single-page="true"
                    background
                    layout="prev, pager, next"
                    :page-size="list.per_page"
                    :current-page="list.current_page"
                    :total="list.total"
                    @current-change="onPage"
                />
            </div>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
    </div>
</template>

<script>
import { float as regexpFloat } from '@/services/regexp'
export default {
    data() {
        return {
            ci: 0,
            fi: 0,
            ti: 1,
            coinList: [
                'USDT'
            ],
            walletList: [
                {
                    name: 'spot',
                    labal: this.$t('wallet.spot'),
                    available: '0.00'
                },
                {
                    name: 'futures',
                    labal: this.$t('wallet.futures'),
                    available: '0.00'
                }
            ],
            walletLabal: {
                spot: this.$t('wallet.spot'),
                futures: this.$t('wallet.futures')
            },
            loading: false,
            formData: {
                coin: 'USDT',
                from: 'spot',
                to: 'futures',
                amount: ''
            },
            loadingRecord: false,
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            timer: null,
            rules: {
                amount: [
                    { required: true, pattern: regexpFloat, trigger: 'blur', message: this.$t('wallet.pea') }
                ]
            }
        }
    },
    created: function() {
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        bindFromTabChange: function() {
            if(this.fi == this.ti){
                this.ti = this.fi == 0 ? 1 : 0
            }
            this.formData.amount = ''
            // this.fatchData()
        },
        bindToTabChange: function() {
            if(this.fi == this.ti){
                this.fi = this.ti == 0 ? 1 : 0
            }
            // this.fatchData()
        },
        setMax: function(){
            this.formData.amount = this.walletList[this.fi].available <= 0 ? '' : this.walletList[this.fi].available * 1
        },
        switchType: function(){
            this.formData.amount = ''
            const fi = this.fi
            const ti  = this.ti
            this.ti = fi
            this.fi = ti
            // this.fatchData()
        },
        fatchData: async function () {
            this.loading = true
            const { data } = await this.$request.get('transfer/info/coin/' + this.coinList[this.ci])
            if(data.coins.length > 0) {
                this.ci = data.coins.indexOf(this.coinList[this.ci])
                this.coinList = data.coins
            }
            this.walletList = data.wallets
            this.loading = false
        },
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                this.loading = true
                this.formData.coin = this.coinList[this.ci]
                this.formData.from = this.walletList[this.fi].name
                this.formData.to = this.walletList[this.ti].name
                await this.$request.post('transfer', this.formData)
                this.formData.amount = ''
                this.fatchData()
                this.fatchRecord()
            } catch (error) {
                //
            }
            this.loading = false
        },
        fatchRecord: async function () {
            try {
                this.loadingRecord = true
                const { data } = await this.$request.get('transfer/log_pc')
                this.list = data
                this.loadingRecord = false
            } catch (error) {
                this.loadingRecord = false
            }
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style>
.el-input-group__appen{
    background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
.input-form{
    width: 480px;
}
</style>